import { render, staticRenderFns } from "./MobileHome.vue?vue&type=template&id=910fc4cc&scoped=true&"
import script from "./MobileHome.vue?vue&type=script&lang=js&"
export * from "./MobileHome.vue?vue&type=script&lang=js&"
import style0 from "./MobileHome.vue?vue&type=style&index=0&id=910fc4cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "910fc4cc",
  null
  
)

export default component.exports