<template>
  <div class="container">
    <div class="content">
      <div class="sub-head">Share Anytime Anywhere</div>
      <div class="sub-des">
        ShareKaro is a popular file sharing app, fast and no data usage. You can
        share all kinds of files to Android/iOS/PC users, including videos,
        apps, photos, music, etc.
      </div>
      <div class="item">
        <div
          class="item-content"
          v-for="(item, index) in itemList"
          :key="index"
          :type="item.type"
          @click="handleDownload(item.type)"
        >
          <div :class="['iconM', 'iconM-' + item.icon]"></div>
          <div class="right">
            <div class="item-des">{{ item.des }}</div>
            <div class="item-title">{{ item.title }}</div>
          </div>
        </div>
      </div>
      <div class="img">
        <img src="@/assets/img/pc_picture.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { getAllUrlParams, getGup } from "@/assets/js/utils.js";
export default {
  name: "HeadContent",
  data() {
    return {
      itemList: [
        { icon: "pc-apk", title: "Download apk", des: "", type: "apk" },
        {
          icon: "pc-google",
          title: "Google Play",
          des: "GET IT ON",
          type: "googlePlay",
        },
        {
          icon: "pc-ios",
          title: "App Store",
          des: "Download on the",
          type: "appStore",
        },
        { icon: "pc-windows", title: "For Windows", des: "", type: "windows" },
      ],
      downloadMap: {
        a: {
          apk: "",
          googlePlay:
            "https://play.google.com/store/apps/details?id=com.sharekaro.app",
          appStore:
            "https://apps.apple.com/us/app/share-karo-share-file-transfer/id1584597372",
          windows: "https://www.sharekaro.app/d/websiteA/sharekaro-setup.exe",
          //   Apk_PC: "https://www.sharekaro.app/d/website/ZShare_pc.apk"
        },
        b: {
          apk: "https://www.sharekaro.app/d/websiteB/ZShare.apk",
          googlePlay:
            "https://play.google.com/store/apps/details?id=com.sharekaro.app",
          appStore:
            "https://apps.apple.com/us/app/share-karo-share-file-transfer/id1584597372",
          windows: "https://www.sharekaro.app/d/websiteB/sharekaro-setup.exe",
          //   Apk_PC: "https://www.sharekaro.app/d/website/ZShare_pc.apk"
        },
        c: {
          apk: "https://www.sharekaro.app/d/websiteC/ZShare.apk",
          googlePlay:
            "https://play.google.com/store/apps/details?id=com.sharekaro.app",
          appStore:
            "https://apps.apple.com/us/app/share-karo-share-file-transfer/id1584597372",
          windows: "https://www.sharekaro.app/d/websiteC/sharekaro-setup.exe",
          //   Apk_PC: "https://www.sharekaro.app/d/website/ZShare_pc.apk"
        },
        d: {
          apk: "https://www.sharekaro.app/d/website/ZShare.apk", // 没用到
          googlePlay:
            "https://play.google.com/store/apps/details?id=com.sharekaro.app",
          appStore:
            "https://apps.apple.com/us/app/share-karo-share-file-transfer/id1584597372",
          windows: "https://www.sharekaro.app/d/website/sharekaro-setup.exe", // 没用到
          //   Apk_PC: "https://www.sharekaro.app/d/website/ZShare_pc.apk"
        },
      },
      downloadUrl: {},
      usoAndroidMap: {
        website: "https://www.sharekaro.app/d/apk/website/sharekaro.apk", // 官网默认链接
        webshare: "https://www.sharekaro.app/d/apk/webshare/sharekaro.apk", // webshare带来的下载
        kol_transfer:
          "https://www.sharekaro.app/d/apk/kol_transfer/sharekaro.apk", // KOL通过介绍传输带来的量
        kol_download:
          "https://www.sharekaro.app/d/apk/kol_download/sharekaro.apk", // KOL通过介绍下载带来的量
        website_3rd:
          "https://www.sharekaro.app/d/apk/website_3rd/sharekaro.apk", // 三方商店带来的量
        ga_search: "https://www.sharekaro.app/d/apk/ga_search/sharekaro.apk", // GA投放带来的量
      },
      usoWindowsMap: {
        website: "https://www.sharekaro.app/d/exe/website/sharekaro-setup.exe",
        webshare:
          "https://www.sharekaro.app/d/exe/webshare/sharekaro-setup.exe",
        kol_transfer:
          "https://www.sharekaro.app/d/exe/kol_transfer/sharekaro-setup.exe",
        kol_download:
          "https://www.sharekaro.app/d/exe/kol_download/sharekaro-setup.exe",
        website_3rd:
          "https://www.sharekaro.app/d/exe/website_3rd/sharekaro-setup.exe",
        ga_search:
          "https://www.sharekaro.app/d/exe/ga_search/sharekaro-setup.exe",
      },
      downlink: "",
      uso: "",
      isAndroid: true,
      isiOS: false,
      gup: "d",
      testId: "",
    };
  },
  mounted() {
    this.downloadUrl = this.downloadMap["d"];
    this.uso =
      getAllUrlParams("uso") === "" ? "website" : getAllUrlParams("uso");
    this.process(this.uso);
    this.isAndroid =
      navigator.userAgent.indexOf("Android") > -1 ||
      navigator.userAgent.indexOf("Adr") > -1;
    this.isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    this.changeStyle();
    this.gup = getGup();
    this.$nextTick(() => {
      let _uso = this.uso;
      class IOCreateor {
        constructor(callback) {
          if (!callback || typeof callback !== "function") {
            throw new Error("need callback[function] param");
          }
          this.io = new IntersectionObserver((entries) => {
            entries.forEach((item) => {
              //判断目标元素是否出现在可视区
              if (item.intersectionRatio > 0) {
                if (item.target) {
                  callback(item.target, item);
                  this.io.unobserve(item.target); //停止监听目标元素
                }
              }
            });
          });
        }
        observe(el) {
          if (!this.io || !el) {
            return;
          }
          this.io.observe(el);
        }

        disconnect() {
          if (!this.io) {
            return;
          }
          this.io.disconnect();
        }
      }
      const myIntersectionObserver = new IOCreateor((node) => {
        let type = node.getAttribute("type");
        let pveCur = `/fe-skWebsite/home/download/${type}`;
        let event = `show_ve_${type}`;
        this.$beyla.report({
          pveCur: pveCur,
          eventName: "show_ve",
          extras: "online_1_0110",
          ab: this.gup,
          type,
        });
        window.gtag("event", event, {
          event_category: "show_ve",
          event_label: pveCur,
          _uso,
        });
      });
      const btnList = Array.prototype.slice.call(
        document.querySelector(".item").children
      );
      btnList.forEach((element) => {
        myIntersectionObserver.observe(element);
      });
    });
  },
  methods: {
    changeStyle() {
      if (!this.isAndroid) {
        this.itemList[0] = this.itemList.splice(2, 1, this.itemList[0])[0];
      }
    },
    process(uso) {
      let allParamsString = "";
      let baseParams = {
        utm_source: uso,
        utm_medium: uso,
        utm_campaign: uso,
      };
      Object.keys(baseParams).forEach(function (key) {
        allParamsString += `&${key}=${baseParams[key]}`;
      });
      this.downloadUrl.googlePlay =
        this.downloadUrl.googlePlay +
        "&referrer=" +
        encodeURIComponent(allParamsString);
      this.downloadUrl.appStore =
        this.downloadUrl.appStore + "?" + allParamsString;
    },
    handleDownload(type) {
      let _uso = this.uso;
      if (type === "apk") {
        this.downlink =
          this.usoAndroidMap[_uso] || this.usoAndroidMap["website"];
        this.$beyla.report({
          pveCur: "/fe-skWebsite/home/download/apk",
          eventName: "click_ve",
          extras: "online_1_0110",
          ab: this.gup,
          type,
        });
        window.gtag("event", "click_ve_apk", {
          event_category: "click_ve",
          event_label: "/fe-skWebsite/home/download/apk",
          _uso,
        });
        window.location = this.downlink;
      } else if (type === "windows") {
        this.downlink =
          this.usoWindowsMap[_uso] || this.usoWindowsMap["website"];
        this.$beyla.report({
          pveCur: "/fe-skWebsite/home/download/windows",
          eventName: "click_ve",
          extras: "online_1_0110",
          ab: this.gup,
          type,
        });
        window.gtag("event", "click_ve_apk", {
          event_category: "click_ve",
          event_label: "/fe-skWebsite/home/download/windows",
          _uso,
        });
        window.location = this.downlink;
      } else if (type === "googlePlay") {
        this.downlink = this.downloadUrl[type];
        this.$beyla.report({
          pveCur: "/fe-skWebsite/home/download/googlePlay",
          eventName: "click_ve",
          extras: "online_1_0110",
          ab: this.gup,
          type,
        });
        window.gtag("event", "click_ve_apk", {
          event_category: "click_ve",
          event_label: "/fe-skWebsite/home/download/googlePlay",
          _uso,
        });
        window.location = this.downlink;
      } else if (type === "appStore") {
        this.downlink = this.downloadUrl[type];
        this.$beyla.report({
          pveCur: "/fe-skWebsite/home/download/appStore",
          eventName: "click_ve",
          extras: "online_1_0110",
          ab: this.gup,
          type,
        });
        window.gtag("event", "click_ve_apk", {
          event_category: "click_ve",
          event_label: "/fe-skWebsite/home/download/appStore",
          _uso,
        });
        window.location = this.downlink;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";

.container {
  //width: 100%;
  height: rem(1268px);
  background: #ffffff;
  padding-top: rem(110px);
  .content {
    //width: 100%;
    height: rem(950px);
    background: url("../../assets/img/top_bg.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    .sub-head {
      font-size: rem(40px);
      font-family: HelveticaNeue-Bold, HelveticaNeue;
      font-weight: bold;
      color: #ffffff;
      line-height: rem(66px);
      padding-top: rem(36px);
    }
    .sub-des {
      font-size: rem(24px);
      font-family: PingFangSC-Regular, PingFang SC;
      color: #ffffff;
      font-weight: 400;
      line-height: rem(33px);
      margin: rem(13px 36px 40px);
    }
    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      //margin-top: 15px;
      .item-content {
        width: rem(300px);
        height: rem(70px);
        background: #333131;
        border-radius: rem(4px);
        display: flex;
        //justify-content: center;
        align-items: center;
        margin-top: rem(30px);

        .right {
          padding-left: rem(20px);
          text-align: center;
          .item-des {
            font-size: rem(15px);
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: rem(18px);
            text-align: left;
          }
          .item-title {
            font-size: rem(28px);
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: rem(33px);
          }
        }
      }
    }
    .img {
      position: relative;
      width: rem(550px);
      top: rem(60px);
      img {
        //position: absolute;
        width: rem(550px);
        background-size: 100% 100%;
      }
    }
  }
}
</style>
