<template>
  <div class="mobile">
    <title-bar></title-bar>
    <header-content></header-content>
    <!-- <swipper></swipper> -->
    <information :infoList="infoList"></information>
    <home-footer></home-footer>
    <back></back>
  </div>
</template>

<script>

import TitleBar from "./Mobile/TitleBar.vue";
import HeaderContent from "./Mobile/HeaderContent.vue";

export default {
  name: "MobileHome",
  data() {
    return {
      infoList: [
        {
          src: require("@/assets/icon/transfer.png"),
          iconSrc: require("@/assets/img/pic_01.png"),
          iconTitle: "TRANSFER",
          title: "Free and fast, maybe the strongest",
          desList: [
            "With Sharekaro, you can share any your loving apps, games, videos, photos and anything.",
            "Share with family and friends. It is totally data free and fast.",
          ],
          bg: "#F4F9FF",
        },
        {
          src: require("@/assets/icon/clean.png"),
          iconSrc: require("@/assets/img/pic_02.png"),
          iconTitle: "CLEAN",
          title: "Cleaner, more professional and assured",
          desList: [
            "With Sharekaro, you can easily clean up your phone trash and increase phone speed.",
            "Relying on our expertise, just take a moment and enjoy your phone like a brand new one.",
          ],
          bg: "#FFFFFF",
        },
        {
          src: require("@/assets/icon/download.png"),
          iconSrc: require("@/assets/img/pic_03.png"),
          iconTitle: "DOWNLOAD",
          title:
            "Download the rich resources of Instagram, Whatsapp, Facebook ,etc.",
          desList: [
            "With ShareKaro, you can download pictures, videos and other resources from Instagram, WhatsApp,Facebook and other platform.",
          ],
          bg: "#F4F9FF",
        },
        {
          src: require("@/assets/icon/files.png"),
          iconSrc: require("@/assets/img/pic_04.png"),
          iconTitle: "FILES",
          title: "You can manage videos, music and other files in your phone.",
          desList: [
            "With ShareKaro, you can manage Videos, Music and other files in your phone, or you can also use ShareKaro as your portable player to play video and music.",
          ],
          bg: "#FFFFFF",
        },
      ],
    };
  },
  components: {
    TitleBar,
    HeaderContent,
    Information: () => import('./Mobile/Information.vue'),
    HomeFooter: () => import('./Mobile/HomeFooter.vue'),
    Back: () => import('./Mobile/Back.vue')
  },
};
</script>

<style lang="scss" scoped>
.mobile {
    width: 100%;
}
</style>
